import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { createStore, getRegistry, withProps } from '@ngneat/elf';

import { AuthToken, User } from '@core/models';
import { environment } from '@env/environment';
import { Storage } from '@core/utils/storage';
import { LoginResponse, UserLogin } from './models/user-login';
import { ResetPassword } from '@modules/auth/models/reset-password';

interface JwtPayload {
  exp: number;
  sub: string;
  type: string;
}

interface AuthProp {
  user: User | null;
  token: AuthToken | null;
}

const authStore = createStore(
  { name: 'auth' },
  withProps<AuthProp>({ user: null, token: null })
);

const AUTH_USER = 'authUser';
const AUTH_TOKEN = 'authToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly baseUrl: string;
  private readonly userUrl: string;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private refreshTokenTimer: any; // NodeJS.Timeout

  constructor(private http: HttpClient) {
    this.baseUrl = `${environment.baseUrl}/v1/login`;
    this.userUrl = `${environment.baseUrl}/v1/user`;
  }

  get user(): User | null {
    let user = authStore.getValue().user;
    if (!user) {
      user = Storage.getItem<User>(AUTH_USER);
    }
    return user;
  }

  get token(): AuthToken | null {
    let token = authStore.getValue().token;
    if (!token) {
      token = Storage.getItem<AuthToken>(AUTH_TOKEN);
    }
    return token;
  }

  login(user: UserLogin): Observable<User> {
    return this.http.post<LoginResponse>(this.baseUrl, user).pipe(
      tap((loginUser) => {
        if (loginUser) {
          this.updateToken({
            access_token: loginUser.access_token,
            refresh_token: loginUser.refresh_token,
            token_type: loginUser.token_type,
          });
          // Auto refresh JWT access token before expire.
          // this.startRefreshTokenTimer();
        }
      }),
      switchMap(() => this.profile()),
      map((loginUser) => loginUser)
    );
  }

  profile(): Observable<User> {
    return this.http.get<User>(`${this.userUrl}`).pipe(
      tap((user) => {
        this.updateUser(user);
      })
    );
  }

  refreshToken() {
    const token = this.token;
    return this.http
      .post<AuthToken>(`${this.baseUrl}/new_access_token`, {
        refresh_token: token?.refresh_token,
      })
      .pipe(
        tap((newToken) => {
          // Skip refresh token option
          // this.updateToken({ ...token, ...newToken });
        })
      );
  }

  logout(): void {
    authStore.update(() => ({ user: null, token: null }));
    Storage.clear();
    this.stopRefreshTokenTimer();
    this.resetStore();
  }

  passwordResetRequest(body: { email: string }): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(
      `${this.userUrl}/password_reset_request`,
      body
    );
  }

  updateTnCAgreement(id: string, body: {isAcceptedTnc: boolean}): Observable<User> {
    return this.http.put<User>(
      `${this.userUrl}/${id}/policy`,
      body
    );
  }

  resetPassword(body: ResetPassword): Observable<unknown> {
    return this.http.post(`${this.userUrl}/reset_password/`, body);
  }

  updateToken(token: AuthToken): void {
    authStore.update((state) => ({ ...state, token }));
    if (token) {
      Storage.setItem<AuthToken>(AUTH_TOKEN, token);
    }
  }

  private startRefreshTokenTimer(): void {
    const jwtBase64: string = this.token?.access_token.split('.')[1] ?? '';
    const jwtToken: JwtPayload = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimer = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimer);
  }

  private updateUser(user: User): void {
    authStore.update((state) => ({ ...state, user }));
    if (user) {
      Storage.setItem<User>(AUTH_USER, user);
    }
  }

  private resetStore(): void {
    getRegistry().forEach((store) => store.reset());
  }
}
